import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import request from '../request';
import { ToastContainer, toast } from "react-toastify";

const title = process.env.REACT_APP_TITLE

class Login extends Component {
  constructor () {
    super();
    this.state = {
      username: '',
      password: '',
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.loginHandler = this.loginHandler.bind(this);
    this.setAppData = this.setAppData.bind(this);
  }

  changeHandler (e) {
    const { id, value } = e.target;
    this.setState({[id]: value});
  }

  setAppData ({ token, name, apiKey }) {
    this.props.setName(name);
    this.props.setApiKey(apiKey);
    this.props.loginAction(token);
  }

  loginHandler () {
    const { username, password } = this.state;
    request(
      'post',
      'authtoken',
      JSON.stringify({ username, password }),
      this.setAppData,
      toast.error
    );
  }

  render () {
    if (this.props.login) {
      return <Redirect to='/' />
    }

    return (
      <Fragment>
        <ToastContainer
         position="top-right"
         autoClose={3000}
         hideProgressBar={true}
         newestOnTop={false}
         closeOnClick
         rtl={false}
        />
        <div className='login'>
          <div className='login-header'>{title}</div>
          <div className='login-inputs'>
            <label htmlFor='username'>Username</label>
            <input type='text' id='username' onChange={this.changeHandler} />
            <label htmlFor='password'>Password</label>
            <input type='password' id='password' onChange={this.changeHandler} />
          </div>
          <div className='button-report' onClick={this.loginHandler}>Login</div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login
});

const mapDispatchToProps = dispatch => ({
  loginAction: payload => dispatch({ type: 'LOGIN', payload}),
  setName: payload => dispatch({ type: 'SET_NAME', payload }),
  setApiKey: payload => dispatch({ type: 'SET_API_KEY', payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
