import React from 'react';
import numeral from 'numeral';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    Legend,
} from 'recharts';

const COLORS = process.env.REACT_APP_CHART_LINE_COLORS.split(' ')

const CustomToolTip = ({payload, type}) => {
    let spend = null;
    let impressions = null;
    let errors = null;
    let responses = null;
    let timeouts = null;
    let labelName = '';
    let tooltipData = [];
    if (payload.length) {
        let data = payload[0].payload;
        spend = data.spend;
        impressions = data.impressions;
        timeouts = data.timeouts;
        errors = data.errors;
        responses = data.responses;
        if (data.hasOwnProperty('hour')) {
            let hour = data.hour.toString();

            if (hour.length === 1) {
                hour = '0' + hour
            } else if (hour.length === 2) {
                hour = hour + ':00';
            }

            labelName = 'Hour of day: ' + hour + ' UTC';
        } else {
            labelName = 'Date: ' + data.date;
        }
    }

    if (type === 'financial') {
        tooltipData = [
            <p key='spend' style={{color: COLORS[0]}}>{`Spend: ${spend}`}</p>,
            <p key='img' style={{color: COLORS[1]}}>{`Impressions: ${numeral(impressions).format('0,0')}`}</p>
        ];
    } else {
        tooltipData = [
            <p key='res' style={{color: COLORS[0]}}>{`Responses: ${numeral(responses).format('0,0')}`}</p>,
            <p key='timeouts' style={{color: COLORS[1]}}>{`Timeouts: ${numeral(timeouts).format('0,0')}`}</p>,
            <p key='errors' style={{color: COLORS[2]}}>{`Errors: ${numeral(errors).format('0,0')}`}</p>,
        ]
    }

    return (
        <div className='custom-tooltip'>
            <p>{labelName}</p>
            {tooltipData}
        </div>
    );
}

export default ({data, dataKey, loading, hideAxis, tooltipType}) => {
    let lines, axis, xAxisDataKey;
    let emptyText = loading ? 'Loading...' : 'No data';

    if (data.length === 0) {
        return <div className='nodata'>{emptyText}</div>
    } else if (data[0].hasOwnProperty('hour')) {
        xAxisDataKey = 'hour';
    } else {
        xAxisDataKey = 'date';
    }

    lines = dataKey.map((dataKey, index) => (
        <Line
            key={index}
            type="monotone"
            dataKey={dataKey}
            stroke={COLORS[index]}
            dot={false}
        />
    ));

    if (hideAxis) {
        axis = null;
    } else {
        axis = [
            <XAxis key='x' dataKey={xAxisDataKey} tickFormatter={value => value}/>,
            <YAxis key='y' tickFormatter={value => numeral(value).format('0.0a')}
                   label={{value: 'Spend', angle: -90, position: 'insideLeft'}}/>
        ];
    }

    return (
        <ResponsiveContainer height={296}>
            <LineChart data={data}>
                {lines}
                {axis}
                <Tooltip content={<CustomToolTip type={tooltipType}/>}/>
                <Legend/>
            </LineChart>
        </ResponsiveContainer>
    );
}
