import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

export default function ({ text }) {
  let textToClipboard = text ? text : 'Not data to copy';
  return (
    <CopyToClipboard text={textToClipboard} onCopy={() => toast.success("Copied")}>
        <div className='button-card button-card-copy'>API Endpoint</div>
    </CopyToClipboard>
  );
}
