import React from 'react';
import numeral from 'numeral';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Legend, Cell} from 'recharts';


const COLORS = ['#3D5AFE', '#EC407A', '#3F51B5', '#9C27B0',
    '#7CB342', '#FFC107', '#E64A19', '#607D8B'];

export default ({ data, dataKey, nameKey, loading }) => {
  const cells = data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />);
  let emptyText = loading ? 'Loading...': 'No data';
  
  if (data.length === 0) {
    return <div className='nodata'>{emptyText}</div>
  }
  
  return (
    <ResponsiveContainer height={296}>
      <PieChart>
        <Pie data={data} cx='50%' cy='50%' dataKey={dataKey} nameKey={nameKey}>{cells}</Pie>
        <Legend iconType='circle'/>
        <Tooltip formatter={value => numeral(value).format('0,0')}/>
      </PieChart>
    </ResponsiveContainer>
  );
};
