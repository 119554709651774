export default function parser (data) {
  let result = '';
  
  if (!data.length) {
    return result;
  }

  result += Object.keys(data[0]).join(",") + '\n';
  data.forEach(obj => {
    result += Object.values(obj).join(",") + '\n';
  });

  return result;
}
