import { store } from './index';
const endpoint = process.env.REACT_APP_ENDPOINT

function showLoad () {
  store.dispatch({ type: 'LOADING_START' });
}

function hideLoad () {
  store.dispatch({ type: 'LOADING_FINISH' });
}

function getUserToken() {
  return window.localStorage.getItem('token') ? window.localStorage.getItem('token') : '';
}

function getData (response) {
  if (Object.prototype.hasOwnProperty.call(response, 'data')) {
    return response.data;
  } else {
    return response;
  }
}

export default (method, url, body, dataCallback, errorCallback) => {
  const options = {
    method,
    body,
    headers: new Headers({ Authorization: `Bearer ${getUserToken()}`, 'Content-Type': 'application/json' }),
  };
  showLoad();
  fetch(`${endpoint}/${url}`, options)
    .then(response => {
      if (response.status === 200) {
        hideLoad();
        if (!response.headers.get('Content-Type') || response.headers.get('Content-Type').match(/text/)) {
          return response.text();
        }

        if (response.headers.get('Content-Type').match(/application\/json/)) {
          return response.json();
        }
      } else if (response.status === 204) {
        hideLoad();
        return response;
      } else {
        hideLoad();
        throw response;
      }
    })
    .then(response => {
      if (dataCallback) {
        dataCallback(getData(response))
      }
    })
    .catch(e => {
      hideLoad();
      if (e.status === 400) {
        e.json().then(data => {
          if (errorCallback) {
            errorCallback(data.message);
          }
        });
      } else if (e.status === 401 || e.status === 419) {
        store.dispatch({type: 'LOGOUT'})
      } else if (e.status === 403) {
        if (errorCallback) {
          errorCallback(403);
        }
      } else if (e.status === 500) {
        if (errorCallback) {
          errorCallback(500);
        }
      }
    });
}