const initialState = {
  login: window.localStorage.getItem('token') ? true : false,
  companyName: window.localStorage.getItem('companyName'),
  type: 'ssp',
  dsp: [],
  ssp: [],
  endpoint: null,
  dateFrom: new Date(),
  dateTo: new Date(),
  financial: [],
  financialLoad: false,
  countries: [],
  countriesLoad: false,
  os: [],
  osLoad: false,
  platforms: [],
  platformsLoad: false,
  network: [],
  networkLoad: false,
  loading: false,
  financialUrl: '',
  apiKey: window.localStorage.getItem('apiKey'),
}

function resetData () {
  return {
    financial: [],
    countries: [],
    os: [],
    platforms: [],
    network: [],
    endpoint: null,
  }
}

function mergeData (fieldName,data) {
  let result = [];
  data.forEach(obj => {
    const { impressions, spend } = obj;
    const field = obj[fieldName];
    const findObject = result.find(r => r[fieldName] === field);
    if (findObject) {
      findObject.impressions += impressions;
      findObject.spend += spend;
    } else {
      result.push({...obj});
    }
  });
  return result;
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  if (type === 'SET_NAME') {
    window.localStorage.setItem('companyName', payload);
    return { ...state, companyName: payload };
  }

  if (type === 'SET_API_KEY') {
    window.localStorage.setItem('apiKey', payload);
    return {...state, apiKey: payload};
  }

  if (type === 'LOGIN') {
    window.localStorage.setItem('token', payload);
    return {...state, login: true };
  }

  if (type === 'LOGOUT') {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('companyName');
    window.localStorage.removeItem('apiKey'); 
    return {...initialState, login: false };
  }

  if (type === 'TYPE_TOGGLE') {
    return { ...state, type: payload, ...resetData() };
  }

  if (type === 'FETCH_SSP') {
    return { ...state, ssp: payload.endpoints };
  }

  if (type === 'FETCH_DSP') {
    return { ...state, dsp: payload.endpoints };
  }

  if (type === 'CHANGE_ENDPOINT') {
    return { ...state, endpoint: payload };
  }

  if (type === 'CHANGE_DATE_FROM') {
    let dateFrom = new Date(payload);
    return { ...state, dateFrom };
  }

  if (type === 'CHANGE_DATE_TO') {
    let dateTo = new Date(payload);
    return { ...state, dateTo };
  }

  if (type === 'FETCH_FINANCE') {
    let financial = payload;
    return { ...state, financial, financialLoad: false };
  }

  if (type === 'FETCH_COUNTRIES') {
    let countries = mergeData('country', payload);
    return { ...state, countries, countriesLoad: false };
  }

  if (type === 'FETCH_OS') {
    let os = mergeData('os', payload);
    return { ...state, os, osLoad: false };
  }

  if (type === 'FETCH_PLATFORMS') {
    return { ...state, platforms: payload, platformsLoad: false };
  }

  if (type === 'FETCH_NETWORK') {
    return { ...state, network: payload, networkLoad: false };
  }

  if (type === 'FETCHING_START') {
    return {
      ...state,
      platformsLoad: true,
      osLoad: true,
      financialLoad: true,
      countriesLoad: true,
      networkLoad: true,
    }
  }

  if (type === 'LOADING_START') {
    return { ...state, loading: true };
  }

  if (type === 'LOADING_FINISH') {
    return { ...state, loading: false };
  }

  if (type === 'DATE_TODAY') {
    let today = new Date();
    return { ...state, dateFrom: today, dateTo: today };
  }

  if (type === 'DATE_YESTERDAY') {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return { ...state, dateFrom: yesterday, dateTo: yesterday }
  }

  if (type === 'DATE_MONTH') {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    firstDay.setHours(12);
    return { ...state, dateFrom: firstDay, dateTo: today };
  }

  if (type === 'SET_FINANCIAL_URL') {
    return { ...state, financialUrl: payload };
  }

  return state;
};
