import React, { Fragment, Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { Header, Tools, Content, Login, Loading } from './components/index';
import request from './request';
import './scss/index.scss'

class MainContent extends Component {
  componentDidMount () {
    const { fetchDSP, fetchSSP } = this.props;
    request('get', 'ssp', null, fetchSSP);
    request('get', 'dsp', null, fetchDSP);
  }

  renderTool = () => {
    if (this.props.type === 'ssp' && !this.props.ssp.length) {
      return  <Loading />
    } else if (this.props.type === 'dsp' && !this.props.dsp.length) {
      return  <Loading />
    } else {
      return <Tools />
    }
  }

  render () {
    return (
      <Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <Loading />
        <Header />
        <main>
          {this.renderTool()}
          <Content />
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ dsp, ssp, type }) => ({
  dsp,
  ssp,
  type
});

const mapDispatchToProps = dispatch => ({
  fetchSSP: payload => dispatch({ type: 'FETCH_SSP', payload }),
  fetchDSP: payload => dispatch({ type: 'FETCH_DSP', payload }),
});

const Main = connect(mapStateToProps, mapDispatchToProps)(MainContent)

const mapStateToPropsApp = state => ({ login: state.login });

const defaultPath = (login) => {
  return login ? <Main /> : <Redirect to="/login" />;
}

const App = ({ login }) => (
  <Router>
    <Switch>
      <Route path='/login' component={Login} />
      <Route path='/'render={() => defaultPath(login)} />
    </Switch>
  </Router>
);

export default connect(mapStateToPropsApp)(App);
