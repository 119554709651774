import React from 'react';
import { connect } from 'react-redux';

class Loading extends React.Component {
  constructor () {
    super();
    this.hideBar = this.hideBar.bind(this);
  }

  componentDidMount () {
    this.bar.addEventListener('animationend', this.hideBar);
  }
  
  hideBar () {
    if (this.bar.className === 'loading-bar-done') {
      this.bar.className = 'loading-bar-hidden';
    }
  }

  render () {
    let className = this.props.loading ? 'loading-bar' : 'loading-bar-done';
    return <div ref={i => this.bar = i} className={className}></div>;
  }
}

const mapStateToProps = state => ({ loading: state.loading });

export default connect(mapStateToProps)(Loading);
