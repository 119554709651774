import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from './Card';
import LineChart from './LineChart';
import PieChart from './PieChart';
import Platforms from './Platforms';
const endpoint = process.env.REACT_APP_ENDPOINT

class Content extends Component {
  getNetworkBlock = () => {
    const { type, network, networkLoad } = this.props;
    if (type === 'dsp') {
      return (
        <div className='col'>
          <Card title='Network activity' buttonText='Export .csv' data={network}>
            <LineChart
              data={network}
              dataKey={["responses", "timeouts", "errors"]}
              loading={networkLoad}
              hideAxis={true}
            />
          </Card>
        </div>
      );
    } else {
      return null;
    }
  }

  render () {
    const {
      financial,
      countries,
      os,
      platforms,
      financialLoad,
      countriesLoad,
      osLoad,
      platformsLoad,
      financialUrl,
      apiKey,
    } = this.props;

    return (
      <div className='content'>
        <div className='row'>
          <Card
            title='Financial chart'
            buttonText='Financial report .csv'
            data={financial}
            copyText={`${endpoint}/${financialUrl}&apiKey=${apiKey}`}
          >
            <LineChart
              data={financial}
              dataKey={['spend']}
              loading={financialLoad}
              tooltipType='financial'
            />
          </Card>
          <div className='row'>
            <div className='col'>
              <Card
                buttonText='Platforms report'
                title='Platforms'
                data={platforms}
              >
                <Platforms data={platforms} loading={platformsLoad}/>
              </Card>
            </div>
            {this.getNetworkBlock()}    
            <div className='col'>
              <Card title='Countries' data={countries}>
                <PieChart
                  data={countries}
                  dataKey='impressions'
                  nameKey='country'
                  loading={countriesLoad}
                />
              </Card>
            </div>
            <div className='col'>
              <Card title='Operating systems' data={os}>
                <PieChart
                  data={os}
                  dataKey='impressions'
                  nameKey='os'
                  loading={osLoad}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  type: state.type,
  financial: state.financial,
  financialLoad: state.financialLoad,
  countries: state.countries,
  countriesLoad: state.countriesLoad,
  os: state.os,
  osLoad: state.countriesLoad,
  platforms: state.platforms,
  platformsLoad: state.platformsLoad,
  network: state.network,
  networkLoad: state.networkLoad,
  financialUrl: state.financialUrl,
  apiKey: state.apiKey,
});

export default connect(mapStateToProps)(Content);
